import React, { useState, useEffect } from "react"
import { Diseases, Loading } from "../../components"
import axios from "axios"
import info from "./img/info.png"

export const Result = ({ data, setStage }) => {
	const [result, setResult] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setTimeout(() => {
			axios({
				method: "POST",
				url: "https://middle2.dicdoc.ai/analyze/",
				data: data,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}).then((res) => {
				setResult(res.data)
				console.log(res.data)
				setLoading(false)
			}).catch((err) => {
				console.log(err.response)
				setLoading(false)
			})
		}, 100)
	}, [data])

	const restart = () => {
		setStage(1)
	}

	return (
		<>
			{loading ? <Loading /> :
				<div className="results">
					<h1>Skin Image Scan</h1>
					<h5>Powered by Autoderm®</h5>
					{result?.predictions != null ?
						<>
							<h4>Based on your image, here are the 5 most likely skin conditions ranked in order from top to bottom. Click on the answers for more information.</h4>
							<h4>Get an STD Kit or ask a dermatologist.</h4>
							<h4>*Your results are not stored - take a screenshot to have a personal record</h4>
							<div className="results-info-container">
								{result.predictions.map((name, i) => <Diseases key={i} diseas_name={name.name} possibility={name.confidence} link={name.readMoreUrl} />)}
							</div>
							<div className="results-info">
								<img src={info} alt="info" />
								<p>This is not a medical diagnosis, if in doubt please seek advice from a medical professional.</p>
							</div>
						</>
						:
						<div>
							<h1>No skin detected in the image</h1>
							<p>Please try again</p>
						</div>
					}
					<div className="down-buttons">
						<p className="ask-dermatologist"><a href="https://bit.ly/3OHFe5I" target="_blank" id="ASK-DERM"><button>Ask a dermatologist</button></a></p>
						<p className="start-again"><button onClick={restart}>Start again</button></p>
					</div>
				</div>
			}
		</>
	)
}
