import React from "react"
import LoadingGif from "./img/loading_without_bg.gif"

export const Loading = () => {
	return (
		<div className="loading">
			<h1>Processing...</h1>
			<div className="loading-picture">
				<img alt="loading" src={LoadingGif} />
			</div>
			<div className="loading-information">
				<h3>Your upload is now being analysed and compared to a library of over</h3>
				<h1>500,000 Images</h1>
			</div>
		</div>
	)
}
