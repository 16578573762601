import React, { useState } from "react"
import './App.scss'
import { Scanner, Result } from "./stages"

export const App = () => {
	const [stage, setStage] = useState(1)
	const [image, setImage] = useState(null)

	return (
		<div className="app">
			{stage === 1 && <Scanner setStage={setStage} setImage={setImage} />}
			{stage === 2 && <Result data={image} setStage={setStage} />}
		</div>
	);
}
