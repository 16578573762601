const links = [
	{
		name: "https://www.firstderm.com/abscess",
		link: "https://bit.ly/36CrrMT"
	},
	{
		name: "https://www.firstderm.com/acne-vulgaris-zits/",
		link: "https://bit.ly/3NDE1Mf"
	},
	{
		name: "https://www.firstderm.com/acne-vulgaris-zits/",
		link: "https://bit.ly/3DpPbiW"
	},
	{
		name: "https://www.firstderm.com/acne-scar-solutions/",
		link: "https://bit.ly/3iWF5N3"
	},
	{
		name: "https://www.firstderm.com/acne-vulgaris-zits/",
		link: "https://bit.ly/3LsJYd3"
	},
	{
		name: "https://www.firstderm.com/acne-vulgaris-zits/",
		link: "https://bit.ly/3Dq2zDL"
	},
	{
		name: "https://www.firstderm.com/actinic-keratosis/",
		link: "https://bit.ly/35p3J5W"
	},
	{
		name: "https://www.firstderm.com/angioma/",
		link: "https://bit.ly/3JWgHY0"
	},
	{
		name: "https://www.firstderm.com/atopic-eczema/",
		link: "https://bit.ly/3iSz3x0"
	},
	{
		name: "https://www.firstderm.com/mole-congenital-nevus/",
		link: "https://bit.ly/3DxQEnx"
	},
	{
		name: "https://www.firstderm.com/balanitis/",
		link: "https://bit.ly/3tSyE3U"
	},
	{
		name: "https://www.firstderm.com/basal-cell-carcinoma-basalioma/",
		link: "https://bit.ly/3qOSRWs"
	},
	{
		name: "https://www.firstderm.com/lyme-disease/",
		link: "https://bit.ly/3NCeJOA"
	},
	{
		name: "https://www.firstderm.com/chilblains-pernio/",
		link: "https://bit.ly/3qQ5GzW"
	},
	{
		name: "https://www.firstderm.com/contact-dermatitis/",
		link: "https://bit.ly/3J7kNLP"
	},
	{
		name: "https://www.firstderm.com/intradermal-nevus/",
		link: "https://bit.ly/36CzHwn"
	},
	{
		name: "https://www.firstderm.com/irritative-eczema-irritant-contact-dermatitis/",
		link: "https://bit.ly/3IW1bKa"
	},
	{
		name: "https://www.firstderm.com/dermatofibroma/",
		link: "https://bit.ly/3NDPDyP"
	},
	{
		name: "https://www.firstderm.com/dyshidrotic-eczema-dyshidrosis/",
		link: "https://bit.ly/3NARILT"
	},
	{
		name: "https://www.firstderm.com/itchy-rash-pictures-6-most-common-and-their-treatment/",
		link: "https://bit.ly/3uIR2eN"
	},
	{
		name: "https://www.firstderm.com/epidermal-cyst/",
		link: "https://bit.ly/3DrO9Dp"
	},
	{
		name: "https://www.firstderm.com/erysipelas/",
		link: "https://bit.ly/3K7Jf0w"
	},
	{
		name: "https://www.firstderm.com/folliculitis/",
		link: "https://bit.ly/3iSwFq0"
	},
	{
		name: "https://www.firstderm.com/folliculitis/",
		link: "https://bit.ly/3IW1Gnw"
	},
	{
		name: "https://www.firstderm.com/genital-herpes/",
		link: "https://bit.ly/3NMkfhZ"
	},
	{
		name: "https://www.firstderm.com/genital-warts/",
		link: "https://bit.ly/3uG1hR5"
	},
	{
		name: "https://www.firstderm.com/granuloma-annulare/",
		link: "https://bit.ly/3uK8gbF"
	},
	{
		name: "https://www.firstderm.com/broken-blood-vessels/",
		link: "https://bit.ly/3JWaTxE"
	},
	{
		name: "https://www.firstderm.com/herpes-simplex/",
		link: "https://bit.ly/3Lv91MA"
	},
	{
		name: "https://www.firstderm.com/herpes-zoster-shingles/",
		link: "https://bit.ly/3IV7pu7"
	},
	{
		name: "https://www.firstderm.com/impetigo/",
		link: "https://bit.ly/35qDDzv"
	},
	{
		name: "https://www.firstderm.com/insect-bite/",
		link: "https://bit.ly/383m89l"
	},
	{
		name: "https://www.firstderm.com/intertrigo/",
		link: "https://bit.ly/38kfrjD"
	},
	{
		name: "https://www.firstderm.com/keloid/",
		link: "https://bit.ly/3JXju3c"
	},
	{
		name: "https://www.firstderm.com/keratosis-pilaris/",
		link: "https://bit.ly/3wRBiZC"
	},
	{
		name: "https://www.firstderm.com/lentigo-solaris-liver-spots/",
		link: "https://bit.ly/3LrVC7X"
	},
	{
		name: "https://www.firstderm.com/lichen-planus/",
		link: "https://bit.ly/373jhg3"
	},
	{
		name: "https://www.firstderm.com/lichen-sclerosus-et-atrophicus/",
		link: "https://bit.ly/3JXM9VH"
	},
	{
		name: "https://www.firstderm.com/livedo-reticularis/",
		link: "https://bit.ly/3DorGXM"
	},
	{
		name: "https://www.firstderm.com/malignant-melanoma/",
		link: "https://bit.ly/3tUcDBU"
	},
	{
		name: "https://www.firstderm.com/melasma-chloasma/",
		link: "https://bit.ly/35qK8Cz"
	},
	{
		name: "https://www.firstderm.com/molluscum-contagiosum-water-warts/",
		link: "https://bit.ly/3x7Ctof"
	},
	{
		name: "https://www.firstderm.com/neurodermatitis/",
		link: "https://bit.ly/3DoFIII"
	},
	{
		name: "https://www.firstderm.com/mole-congenital-nevus/",
		link: "https://bit.ly/3qOX8cm"
	},
	{
		name: "https://www.firstderm.com/pearly-penile-papules/",
		link: "https://bit.ly/370Et6G"
	},
	{
		name: "https://www.firstderm.com/perioral-dermatitis/",
		link: "https://bit.ly/36G8wRa"
	},
	{
		name: "https://www.firstderm.com/petechia-or-purpura/",
		link: "https://bit.ly/3JXMHed"
	},
	{
		name: "https://www.firstderm.com/pityriasis-rosea/",
		link: "https://bit.ly/383ngK7"
	},
	{
		name: "https://www.firstderm.com/pityriasis-versicolor/",
		link: "https://bit.ly/3NvXgYl"
	},
	{
		name: "https://www.firstderm.com/hyperpigmentation/",
		link: "https://bit.ly/35p1bon"
	},
	{
		name: "https://www.firstderm.com/razor-bumps-ingrown-hairs-face/",
		link: "https://bit.ly/3NCKTtx"
	},
	{
		name: "https://www.firstderm.com/psoriasis/",
		link: "https://bit.ly/3NCkH1W"
	},
	{
		name: "https://www.firstderm.com/",
		link: "https://bit.ly/3qOKaLQ"
	},
	{
		name: "https://www.firstderm.com/rosacea/",
		link: "https://bit.ly/3JU196Y"
	},
	{
		name: "https://www.firstderm.com/scabies/",
		link: "https://bit.ly/3NMmMbZ"
	},
	{
		name: "https://www.firstderm.com/keloid/",
		link: "https://bit.ly/3iV6P4E"
	},
	{
		name: "https://www.firstderm.com/sebaceous-gland/",
		link: "https://bit.ly/3wRNmdr"
	},
	{
		name: "https://www.firstderm.com/seborrhoeic-dermatitis/",
		link: "https://bit.ly/3wRANyT"
	},
	{
		name: "https://www.firstderm.com/seborrheic-keratosis/",
		link: "https://bit.ly/3uE9Agw"
	},
	{
		name: "https://www.firstderm.com/squamous-cell-carcinoma/",
		link: "https://bit.ly/3uFGiOo"
	},
	{
		name: "https://www.firstderm.com/syphilis/",
		link: "https://bit.ly/3wRLq4W"
	},
	{
		name: "https://www.firstderm.com/tinea-corporis/",
		link: "https://bit.ly/3wNSAXK"
	},
	{
		name: "https://www.firstderm.com/fungal-infections/",
		link: "https://bit.ly/3tRfWtG"
	},
	{
		name: "https://www.firstderm.com/urticaria-hives/",
		link: "https://bit.ly/370XnKo"
	},
	{
		name: "https://www.firstderm.com/vasculitis/",
		link: "https://bit.ly/3NAXm0x"
	},
	{
		name: "https://www.firstderm.com/verruca-vulgaris-common-warts/",
		link: "https://bit.ly/38gtvKQ"
	},
	{
		// name: "https://www.firstderm.com/spots-and-rashes-caused-by-viruses/",
		name: "https://www.firstderm.com/viruses-that-cause-a-rash/",
		link: "https://bit.ly/3tTkdNf"
	},
	{
		name: "https://www.firstderm.com/vitiligo/",
		link: "https://bit.ly/3qLReZG"
	},
	{
		name: "https://www.firstderm.com/covid-19-coronavirus-resource/",
		link: "https://bit.ly/3DorGXM"
	},
	{
		name: "https://www.firstderm.com/pyogenic-granuloma/",
		link: "https://bit.ly/3oRCjvx"
	}
]

export default links
