import React, { useState, useRef, useCallback } from "react"

import Checkbox from '@mui/material/Checkbox'

import bigCam from "./img/big-camera.png"
import rl from "./img/rules_new.png"

import Webcam from "react-webcam"

import pdf from './documents/Terms.pdf'


export const Scanner = ({ setImage, setStage }) => {
	const [open, setOpen] = useState(1)
	const [rules, setRules] = useState(false)
	const [newImage, setNewImage] = useState(false)
	const [checked, setChecked] = useState(false)

	const inputRef = useRef(null)
	const webcamRef = useRef(null)

	const isMobile = window.innerWidth <= 500;

	const onFileChange = useCallback(async (e) => {
		const formData = new FormData()
		formData.set("file", e.target.files[0])
		setImage(formData)
		const reader = new FileReader()
		reader.readAsDataURL(e.target.files[0])
		reader.onload = function () {
			setNewImage(reader.result)
			setOpen(3)
			setTimeout(() => {
				setRules("hide")
			}, 8000)
		}
	})
	const dataURLtoBlob = (dataurl) => {

		var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}

	const capture = useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot()
		const blob = dataURLtoBlob(imageSrc)
		const file = new File([blob], "test.jpg", { type: "image/jpeg" })
		const formData = new FormData()
		formData.set("file", file)
		setImage(formData)
		setNewImage(imageSrc)
	}, [webcamRef])

	const submit = () => {
		if (checked) {
			setStage(2)
		}
	}
	const retake = () => {
		setNewImage(false)
		setOpen(2)
	}

	const openCam = () => {
		setOpen(3)
		setTimeout(() => {
			setRules("hide")
		}, 8000)
	}

	const loadImage = () => {
		inputRef.current.click()
	}

	const onImageClick = () => {
		if (open === 1) {
			// if (isMobile) {
			// 	loadImage()
			// }
			setOpen(2)
		}
	}

	return (
		<div className="scanner">
			<h1>Skin Image Scan</h1>
			<h5>Powered by Autoderm®</h5>
			<h4>Our AI Searches an image database for matching skin conditions anonymously.</h4>
			<figure className="scanner-picture" onClick={onImageClick}>
				<input type="file" className="image-input" ref={inputRef} onChange={onFileChange} />
				<div className="scanner-picture-container">
					{newImage && <img src={newImage} alt="new image" style={{ maxWidth: "450px", maxHeight: "250px", margin: "auto", objectFit: "contain" }} />}
					{open === 2 && !newImage && (
						<>
							<div className={`scanner-picture-container-rules`}>
								<img src={rl} alt="rules" />
							</div>
							<div className="scanner-picture-container-buttons">
								{isMobile && <button onClick={loadImage}>Load image</button>}
								{!isMobile && <button onClick={openCam}>Take image</button>}
								{!isMobile && <button onClick={loadImage}>Load image</button>}
							</div>
						</>
					)}
					{open === 3 && (
						<>
							{!newImage && <div className="webcam">
								<Webcam
									audio={false}
									ref={webcamRef}
									screenshotFormat="image/jpeg"
									width={362}
									mirrored={true}
								/>
							</div>
							}
							<div className="scanner-picture-container-buttons">
								{!newImage && <button onClick={capture}>Capture</button>}
								{newImage && <button onClick={submit}>Submit</button>}
								{newImage && <button onClick={retake}>Retake</button>}
							</div>
						</>
					)}
					{open === 1 && (
						<>
							<img className="scanner-picture-container-img" src={bigCam} alt="photo camera" />
							<figcaption className="scanner-picture-container-caption">Press here to add/take a picture</figcaption>
						</>
					)}
				</div>
			</figure>
			<div className="scanner-terms">
				<Checkbox id="terms" onChange={(e) => setChecked(e.target.checked)} />
				<label htmlFor="terms">Check here to indicate you have read and accepted our <a className="scanner-terms-link" href={pdf} target="_blank">terms and conditions</a></label>
			</div>
			<div className="scanner-information">
				<h5>*We don't collect your image or other personal information</h5>
			</div>
		</div>
	)
}
