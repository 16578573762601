import React, { useState, useEffect } from "react"
import links from "../../stages/Result/links"

export const Diseases = (props) => {
	const std = [
		{ name: "https://www.firstderm.com/syphilis/", link: "https://letsgetchecked.7no9.net/e4Z726" },
		{ name: "https://www.firstderm.com/genital-herpes/", link: "https://letsgetchecked.7no9.net/9WdoZY" },
		{ name: "https://www.firstderm.com/genital-warts/", link: "https://letsgetchecked.7no9.net/P0dGgM" }
	]

	const [stdButton, setStdButton] = useState({ link: false })

	let link
	for (let i in links) {
		if (links[i].name === props.link) {
			link = links[i].link
		}
	}

	const classes = ["possibility"]
	let name
	if (props.possibility >= 0.025) {
		classes.push("red")
		name = "High Possibility"
	}
	else if (props.possibility > 0.015 && props.possibility <= 0.025) {
		classes.push("orange")
		name = "Possible"
	}
	else {
		classes.push("gray")
		name = "Low Possibility"
	}

	useEffect(() => {
		std.map((el) => {
			if (props.link === el.name) {
				setStdButton({ link: el.link })
			}
		})
	}, [])

	return (
		<div className="results-diseases">
			<h3 className="deseases"><a href={link} target="_blank">{props.diseas_name}</a></h3>
			<div className="lower-part">
				<h4 className={classes.join(' ')}>{name}</h4>
				{stdButton.link && <p className="button" ><a href={stdButton.link} target="_blank" id={`STD-KIT`}><button>Get an STD Kit</button></a></p>}
			</div>
		</div>
	)
}
